/* eslint-disable react/no-children-prop */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tag } from 'Common/Components/SearchBar/components/BasicFilter/components/FilterTags';
import { useIntl } from 'react-intl';
import { removeAttrFromSelectedRule } from 'store/Jobs/actions';
import { selectFieldType } from 'store/Filters/selectors';
import { selectDisplayName, selectJobModel } from 'store/Jobs/selectors';
import moment from 'moment';
import Wrapper from './styled';

const Attribute = ({ rule, attribute }) => {
  const model = useSelector(selectJobModel);
  const isDateType = useSelector((state) => selectFieldType(state, model, attribute.field)).includes('date');
  const intl = useIntl();
  const dispatch = useDispatch();
  const parsedValue = isDateType ? moment(attribute.data).format('L') : attribute.data;
  const displayName = useSelector((state) => selectDisplayName(state, attribute.field));

  const getFieldOperator = () => {
    if (attribute.field === 'evidence') {
      if (attribute.operator === 'is_null') return 'empty';
      if (attribute.operator === 'is_not_null') return 'no empty';
    }

    return attribute.operator;
  };

  return (
    <Wrapper>
      <Tag type="key" value={ displayName } />
      <Tag type="op" value={ intl.formatMessage({ id: `model.operator.${attribute.operator}`, defaultMessage: getFieldOperator() }) } valSelected />
      <Tag type="val" value={ intl.formatMessage({ id: `model.valid.${attribute.data}`, defaultMessage: parsedValue }) } showDelete onDelete={ () => dispatch(removeAttrFromSelectedRule(rule, attribute)) } />
    </Wrapper>
  );
};

export default Attribute;
