import React from 'react';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { useIntl } from 'react-intl';
import { selectFields, selectJobModel } from 'store/Jobs/selectors';
import StandardDropdown from 'Common/Components/StandarDropdown';
import { Wrapper, Label } from './styled';

const FieldSelector = ({ field, onChange, command }) => {
  const intl = useIntl();
  const model = useSelector(selectJobModel);
  const options = useSelector((state) => selectFields(state, model));
  const parsedCommand = command === 'update' ? 'replace' : command;
  const filteredOptions = Object.entries(options).filter((obj) => obj[1][parsedCommand]).map((f) => f[0]);

  if (isEmpty(options)) return null;
  return (
    <Wrapper>
      <Label>Field</Label>
      <StandardDropdown
        width="200px"
        field="action"
        options={ filteredOptions.map((key) => ({ desc: intl.formatMessage({ id: `model.field.${key}` }), name: intl.formatMessage({ id: `model.field.${key}` }), id: key })) }
        updateValue={ (f, v) => onChange(v) }
        defaultValue={ field }
        selectObject
      />
    </Wrapper>
  );
};

FieldSelector.defaultProps = {
  field: '',
  command: ''
};

FieldSelector.propTypes = {
  field: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  command: PropTypes.string
};

export default FieldSelector;
