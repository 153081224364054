/* eslint-disable react/jsx-key */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectId, selectJobsSelected } from 'store/Pipelines/selectors';
import { setJobsNewOrder } from 'store/Pipelines/actions';
import TinyCircle from 'Common/Components/TinyCircle';
import colors from 'Styles/colors';
import Step from '../../Step';
import JobItem from '../JobItem';
import {
  Wrapper, JobWrapper, SortableElement, StepContainer
} from './styled';

const JobList = () => {
  const jobs = useSelector(selectJobsSelected);
  const pipelineId = useSelector(selectId);
  const [list, setList] = useState([]);
  const [dragAndDrop, setDragAndDrop] = useState({
    draggedFrom: null,
    draggedTo: null,
    isDragging: false,
    originalOrder: [],
    updatedOrder: []
  });
  const dispatch = useDispatch();
  const smallCircle = <TinyCircle color={ colors.blueGrey } />;

  useEffect(() => {
    setList([...jobs]);
  }, [jobs]);

  const onDragStart = (event) => {
    const initialPosition = Number(event.currentTarget.dataset.position);

    setDragAndDrop({
      ...dragAndDrop,
      draggedFrom: initialPosition,
      isDragging: true,
      originalOrder: list
    });

    event.dataTransfer.setData('text/html', '');
  };

  const onDragOver = (event) => {
    event.preventDefault();
    let newList = dragAndDrop.originalOrder;
    const draggedFrom = dragAndDrop.draggedFrom;
    const draggedTo = Number(event.currentTarget.dataset.position);

    const itemDragged = newList[draggedFrom];
    const remainingItems = newList.filter((item, index) => index !== draggedFrom);

    newList = [
      ...remainingItems.slice(0, draggedTo),
      itemDragged,
      ...remainingItems.slice(draggedTo)
    ];

    if (draggedTo !== dragAndDrop.draggedTo) {
      setDragAndDrop({
        ...dragAndDrop,
        updatedOrder: newList,
        draggedTo
      });
    }
  };

  const onDrop = () => {
    setList(dragAndDrop.updatedOrder);

    setDragAndDrop({
      ...dragAndDrop,
      draggedFrom: null,
      draggedTo: null,
      isDragging: false
    });

    dispatch(setJobsNewOrder(dragAndDrop.updatedOrder));
  };

  const onDragLeave = () => {
    setDragAndDrop({
      ...dragAndDrop,
      draggedTo: null
    });
  };

  return (
    <Wrapper>
      { list.length > 0 && list.map((job, index) => (
        <JobWrapper key={ `job_${job.id}` }>
          <StepContainer>
            <Step icon={ smallCircle } />
          </StepContainer>
          <SortableElement
            data-position={ index }
            draggable
            onDragStart={ onDragStart }
            onDragOver={ onDragOver }
            onDrop={ onDrop }
            onDragLeave={ onDragLeave }
          >
            <JobItem job={ job } pipelineId={ pipelineId } />
          </SortableElement>

        </JobWrapper>
      )) }
    </Wrapper>
  );
};

export default JobList;
