/* eslint-disable react/no-children-prop */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectJobModel, selectJobRules } from 'store/Jobs/selectors';
import isEmpty from 'lodash/isEmpty';
import { LinkAsButton } from 'Common/Components/LinkAsButton/styled';
import { addNewRuleToSelectedJob } from 'store/Jobs/actions';
import { useIntl } from 'react-intl';
import ModelSelector from './components/ModelSelector';
import Rules from './components/Rules';
import {
  Wrapper, Title, Description, Buttons
} from './styled';

const ObjectAndAttributes = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [highlight, setHighlight] = useState(false);
  const rules = useSelector(selectJobRules);
  const model = useSelector(selectJobModel);
  const hasEmptyRule = rules.some((r) => isEmpty(r.attributes));

  let timeout = null;

  const handleAddRule = () => {
    if (hasEmptyRule) {
      setHighlight(true);
      timeout = setTimeout(() => setHighlight(false), 500);
    } else dispatch(addNewRuleToSelectedJob());
  };

  useEffect(() => () => clearTimeout(timeout), [timeout]);

  return (
    <Wrapper showLine={ !!model }>
      <Title children={ intl.formatMessage({ id: 'jobs.objectandattributes.title' }) } />
      <Description children={ intl.formatMessage({ id: 'jobs.objectandattributes.description' }) } />
      <ModelSelector />
      { model && <Rules highlight={ highlight } /> }
      { model && (
      <Buttons>
        <LinkAsButton onClick={ handleAddRule } children={ intl.formatMessage({ id: 'jobs.objectandattributes.addRule' }) } />
      </Buttons>
      ) }
    </Wrapper>
  );
};
export default ObjectAndAttributes;
