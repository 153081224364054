import React from 'react';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from 'Common/Components/IconButton';
import { RefreshIcon } from './styled';
import { getServices } from 'store/Services/actions';
import { getServicesGeneral } from 'store/ServicesGeneral/actions';
import { selectCurrentHost } from 'store/Host/selectors';
import { getAssetServices } from 'store/Contextualization/AssetDetail/actions';

const RefreshButton = ({ entity }) => {
  const dispatch = useDispatch();
  const currentAsset = useSelector(selectCurrentHost);
  const currentAssetId = get(currentAsset, '_id', 0);

  const refresh = {
    services: () => dispatch(getServices()),
    servicesGeneral: () => dispatch(getServicesGeneral()),
    assetServices: () => dispatch(getAssetServices(currentAssetId))
  };

  return (
    <IconButton icon={ <RefreshIcon /> } title="Refresh" onClick={ refresh[entity] } />
  );
};

export default RefreshButton;
