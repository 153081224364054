/* eslint-disable import/prefer-default-export */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import get from 'lodash/get';
import head from 'lodash/head';
import ContextSubMenu from 'Common/Components/ContextMenu/components/ContextSubMenu';
import MenuItem from 'Common/Components/ContextMenu/components/MenuItem';
import { selectVulnsSelected } from 'store/ManageGeneral/selectors';
import { EditIcon } from 'Screens/Contextualization/Manage/components/ManageTable/components/ContextMenu/components/styled';
import EaseOfResolution from './components/EaseOfResolution';
import ConfirmFalsePositive from './components/ConfirmFalsePositive';
import { getVulnRefs, showVulnDetailGeneral, showBulkUpdateModal, setShowContextMenu } from 'store/ManageGeneral/actions';

export const Edit = () => {
  const dispatch = useDispatch();
  const vulnsSelected = useSelector(selectVulnsSelected);
  const isBulk = vulnsSelected.length > 1;
  const selectedVuln = head(vulnsSelected);
  const selectedVulnID = get(selectedVuln, '_id', null);
  const intl = useIntl();

  const goToTabDetail = (selectedTab, inputName) => {
    if (isBulk) dispatch(showBulkUpdateModal(inputName, ''));
    else if (selectedVulnID) dispatch(showVulnDetailGeneral(selectedVulnID, selectedTab));
    dispatch(setShowContextMenu(false));
  };

  const onNameEdit = () => {
    dispatch(showBulkUpdateModal('name', isBulk ? '' : selectedVuln.name));
    dispatch(setShowContextMenu(false));
  };
  const onRefEdit = () => {
    if (isBulk) dispatch(showBulkUpdateModal('references', []));
    else dispatch(getVulnRefs(selectedVulnID));
    dispatch(setShowContextMenu(false));
  };
  const onImpactEdit = () => {
    dispatch(showBulkUpdateModal('impact', isBulk ? [] : Object.keys(vulnsSelected[0].impact).filter((item) => vulnsSelected[0].impact[item] === true)));
    dispatch(setShowContextMenu(false));
  };
  const onPolicyEdit = () => {
    dispatch(showBulkUpdateModal('policy violations', isBulk ? [] : selectedVuln.policyviolations));
    dispatch(setShowContextMenu(false));
  };

  return (
    <ContextSubMenu
      icon={ <EditIcon /> }
      title={ intl.formatMessage({ id: 'manage.cm.edit' }) }
    >
      <MenuItem title={ intl.formatMessage({ id: 'manage.cm.edit.item.name' }) } onClick={ onNameEdit } />
      <MenuItem title={ intl.formatMessage({ id: 'manage.cm.edit.item.data' }) } onClick={ () => goToTabDetail('technicalDetails', 'data') } />
      <MenuItem title={ intl.formatMessage({ id: 'manage.cm.edit.item.description' }) } onClick={ () => goToTabDetail('general', 'description') } />
      <MenuItem title={ intl.formatMessage({ id: 'manage.cm.edit.item.resolution' }) } onClick={ () => goToTabDetail('general', 'resolution') } />
      <MenuItem title={ intl.formatMessage({ id: 'manage.cm.edit.item.references' }) } onClick={ onRefEdit } />
      <MenuItem title={ intl.formatMessage({ id: 'manage.cm.edit.item.impact' }) } onClick={ onImpactEdit } />
      <MenuItem title={ intl.formatMessage({ id: 'manage.cm.edit.item.policyViolations' }) } onClick={ onPolicyEdit } />
      <ConfirmFalsePositive />
      <EaseOfResolution />
    </ContextSubMenu>
  );
};
