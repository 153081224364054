import lowerCase from 'lodash/lowerCase';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setTasks } from 'store/Jobs/actions';
import { selectJobTasks } from 'store/Jobs/selectors';
import TaskItem from '../TaskItem';
import { Wrapper } from './styled';

const TaskList = () => {
  const tasks = useSelector(selectJobTasks);
  const dispatch = useDispatch();

  const onChangeItem = (index, command, field, value) => {
    const newTasks = [
      ...tasks
    ];
    newTasks[index] = {
      ...newTasks[index],
      command,
      field,
      value
    };
    dispatch(setTasks(newTasks));
  };

  const onRemoveItem = (index) => {
    const newTasks = [
      ...tasks
    ];

    newTasks[index] = {
      ...newTasks[index],
      deleted: true
    };

    dispatch(setTasks(newTasks));
  };

  return (
    <Wrapper>
      {/* eslint-disable-next-line react/no-array-index-key */}
      { tasks && tasks.map((task, index) => !task.deleted && <TaskItem index={ index } command={ lowerCase(task.command) } field={ task.field } value={ task.value } key={ `taskItem-${index}-${task.id}` } onChange={ onChangeItem } onRemove={ () => onRemoveItem(index) } />) }
    </Wrapper>
  );
};
export default TaskList;
