import * as types from './types';

export const initialState = {
  isFetching: false,
  error: false,
  errorMessage: '',
  list: [], // jobsList
  searchQuery: '', // search query
  jobSelected: {
    id: 0,
    rules: [],
    tasks: [],
    model: '',
    pipelines: [],
    name: '',
    description: '',
    enabled: false
  }, // the current job that was selected by the user
  fields: { // fields by model
    vulnerability: {},
    asset: {}
  },
  models: [
    'vulnerability',
    'asset'
  ]
};

function jobs (state = initialState, action) {
  switch (action.type) {
    case types.JOBS_SET_RULES: {
      return {
        ...state,
        jobSelected: {
          ...state.jobSelected,
          rules: action.rules
        }
      };
    }
    case types.JOBS_GET_DATA: {
      return {
        ...state,
        list: action.jobs.sort((a, b) => {
          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;
          return 0;
        })
      };
    }
    case types.JOBS_FAIL: {
      return {
        ...state,
        error: true,
        errorMessage: action.errorMessage
      };
    }
    case types.JOBS_SEARCH: {
      return {
        ...state,
        searchQuery: action.searchQuery
      };
    }
    case types.JOBS_VIEW: {
      return {
        ...state,
        jobSelected: action.job
      };
    }
    case types.JOBS_GET_TASKS_FIELDS: {
      return {
        ...state,
        fields: {
          vulnerability: action.vulnFields,
          asset: action.hostFields
        },
        models: [
          ...initialState.models
        ]
      };
    }
    case types.JOBS_NEW: {
      return {
        ...state,
        jobSelected: {
          ...initialState.jobSelected
        }
      };
    }
    case types.JOBS_ADD_TASK: {
      return {
        ...state,
        jobSelected: {
          ...state.jobSelected,
          tasks: [
            ...state.jobSelected.tasks,
            {
              id: 0,
              command: '',
              field: '',
              value: '',
              deleted: false
            }
          ]
        }
      };
    }
    case types.JOBS_SET_NAME: {
      return {
        ...state,
        jobSelected: {
          ...state.jobSelected,
          name: action.name
        }
      };
    }
    case types.JOBS_SET_DESCRIPTION: {
      return {
        ...state,
        jobSelected: {
          ...state.jobSelected,
          description: action.description
        }
      };
    }
    case types.JOBS_SET_MODEL: {
      return {
        ...state,
        jobSelected: {
          ...state.jobSelected,
          model: action.model,
          rules: [],
          tasks: []
        }
      };
    }
    case types.JOBS_SET_TASKS: {
      return {
        ...state,
        jobSelected: {
          ...state.jobSelected,
          tasks: action.tasks
        }
      };
    }
    case types.JOBS_RESET_ERROR: {
      return {
        ...state,
        error: false,
        errorMessage: ''
      };
    }
    default: {
      return state;
    }
  }
}

export default jobs;
