import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectHostEditCreateIp, selectHostEditCreateErrorMessage } from 'store/HostEditCreate/selectors';
import { setField } from 'store/HostEditCreate/actions';
import { ErrorMessage, TextField, Wrapper } from './styled';

const AssetNameInput = () => {
  const dispatch = useDispatch();

  const ip = useSelector((state) => (selectHostEditCreateIp(state)));
  const errorMessage = useSelector((state) => (selectHostEditCreateErrorMessage(state)));

  const failed = errorMessage && errorMessage.length > 0;

  return (
    <Wrapper>
      <TextField title="Asset*" name="ip" width="145px" placeholder="255.255.255.255" value={ ip } onChange={ (value) => dispatch(setField('ip', value)) } className="ip" error={ failed } />
      { failed ? <ErrorMessage> { errorMessage } </ErrorMessage> : null }
    </Wrapper>
  );
};

export default AssetNameInput;
