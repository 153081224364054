import { LinkAsButton } from 'Common/Components/LinkAsButton/styled';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addTask, getFields } from 'store/Jobs/actions';
import { selectJobModel } from 'store/Jobs/selectors';
import TaskList from './components/TaskList';
import {
  Wrapper, Title, Description, Buttons
} from './styled';

const Tasks = () => {
  const dispatch = useDispatch();
  const model = useSelector(selectJobModel);

  useEffect(() => {
    dispatch(getFields());
  }, [dispatch]);

  const handleAddTask = () => dispatch(addTask());

  return model && (
    <Wrapper>
      <Title>Tasks</Title>
      <Description>Configure tasks to process the evaluated data</Description>
      <TaskList />
      <Buttons>
        <LinkAsButton onClick={ handleAddTask }>+ Add Task</LinkAsButton>
      </Buttons>
    </Wrapper>
  );
};
export default Tasks;
