export const JOBS_FETCHING = 'JOBS_FETCHING';
export const JOBS_GET_DATA = 'JOBS_GET_DATA';
export const JOBS_FAIL = 'JOBS_FAIL';
export const JOBS_SEARCH = 'JOBS_SEARCH';
export const JOBS_VIEW = 'JOBS_VIEW';
export const JOBS_GET_TASKS_FIELDS = 'JOBS_GET_TASKS_FIELDS';
export const JOBS_NEW = 'JOBS_NEW';
export const JOBS_ADD_TASK = 'JOBS_ADD_TASK';
export const JOBS_SET_RULES = 'JOBS_SET_RULES';
export const JOBS_SET_MODEL = 'JOBS_SET_MODEL';
export const JOBS_SET_TASKS = 'JOBS_SET_TASKS';
export const JOBS_SET_NAME = 'JOBS_SET_NAME';
export const JOBS_SET_DESCRIPTION = 'JOBS_SET_DESCRIPTION';
export const JOBS_RESET_ERROR = 'JOBS_RESET_ERROR';
